import React, {useCallback} from 'react';
import ReactCountryFlag from "react-country-flag";
import {useTranslation} from "react-i18next";
import '../i18n';
import logo from "../assets/images/logo.svg";

const languages = [
    {code: "ko", label: "한국어", countryCode: "KR"},
    {code: "en", label: "English", countryCode: "US"},
    {code: "ja", label: "日本語", countryCode: "JP"},
    {code: "zh", label: "中國語", countryCode: "CN"},
];

// 리렌더링을 방지하기 위해 React.memo 사용
const LanguageButton = React.memo(({countryCode, label, onPress}) => (
    <button
        className="w-48 px-4 py-2 border border-black rounded-lg bg-black flex items-center justify-center gap-2 hover:bg-gray-800 transition-colors"
        onClick={onPress}>
        <ReactCountryFlag
            countryCode={countryCode}
            svg
            style={{
                width: "1.6em",
                height: "1.6em",
                borderRadius: "9999px"
            }}
            aria-label={label}
        />
        <p className="text-white text-center">{label}</p>
    </button>
));

const SelectLanguage = ({onLanguageSelect}) => {
    const {i18n} = useTranslation();

    // 메모이제이션을 위해 useCallback 사용
    const changeLanguage = useCallback((language) => {
        i18n.changeLanguage(language);
        onLanguageSelect();
    }, [i18n, onLanguageSelect]);

    return (
        <div className="flex flex-col justify-center items-center gap-6 px-4 flex-1">
            {/* 상단 로고 및 텍스트 섹션 */}
            <div className="text-center">
                <img className="w-32 h-auto mb-5 mx-auto" src={logo} alt="Kodak Logo"/>
                <p className="font-semibold">언어를 선택하세요</p>
                <p className="font-semibold">Choose your preferred language</p>
            </div>

            {/* 언어 선택 버튼 섹션 */}
            <div className="flex flex-col items-center space-y-3 w-full max-w-sm">
                {languages.map(({code, label, countryCode}) => (
                    <LanguageButton
                        key={code}
                        countryCode={countryCode}
                        label={label}
                        onPress={() => changeLanguage(code)}
                    />
                ))}
            </div>
        </div>
    );
};

export default SelectLanguage;
