import {Route, Routes} from "react-router-dom";
import ViewPhotoPage from "./pages/ViewPhotoPage";
import PageNotFoundPage from "./pages/PageNotFoundPage";
import HomePage from "./pages/HomePage";
import SelectLanguage from "./pages/SelectLanguage";
import './i18n';

function App() {
  return (
      <Routes>
          <Route index path="/v/:transactionId" element={<ViewPhotoPage/>}/>
          <Route path="/select" element={<SelectLanguage/>}/>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/*" element={<PageNotFoundPage/>}/>
      </Routes>
  );
}

export default App;
