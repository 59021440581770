/**
 * @author : 다니엘 스튜디오
 * @mailto : hello@kimdaniel.studio
 * @created : 2024-04-16
 */

import React from 'react';
import BaseLayout from "../components/layout/BaseLayout";
import logo from '../assets/images/logo.svg'; 


const HomePage = () => {
    return (
        <BaseLayout>
            <div className="min-h-screen flex flex-col justify-center items-center">
                <div className="p-3">
                    <img className="w-24 h-auto mb-2" src={logo} alt="Kodak Logo"/>
                    <span className="font-semibold">가까운 코닥 포토에서 추억을 만들어 보세요 :)</span>
                </div>
            </div>
        </BaseLayout>
    );
};

export default HomePage;