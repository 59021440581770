/**
 * @author : 다니엘 스튜디오
 * @mailto : hello@kimdaniel.studio
 * @created : 2024-04-16
 */

import React, {memo, useCallback} from 'react';
import {Camera, CircleHelp, FileArchive, Image, Video} from 'lucide-react';
import logo from '../../assets/images/logo.svg';
import {useTranslation} from 'react-i18next';

const formatDate = (dateString) => {
    if (!dateString) return '-';

    const date = new Date(dateString);
    if (isNaN(date.getTime())) return '-';

    return date.toISOString().split('T')[0]; // Returns 'YYYY-MM-DD'
};

const getMimeType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    const mimeTypes = {
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        gif: 'image/gif',
        webp: 'image/webp',
        mp4: 'video/mp4',
        mov: 'video/quicktime',
        avi: 'video/x-msvideo',
        zip: 'application/zip',
    };
    return mimeTypes[extension] || 'application/octet-stream';
};

const ViewPhoto = memo(({ imageUrl, videoUrl, originalUrl, createAt }) => {
    const { t } = useTranslation();

    const handleDownload = useCallback(async (fileName) => {
        try {
            const response = await fetch(`https://kr.object.ncloudstorage.com/new-kodak/${fileName}`);
            if (!response.ok) throw new Error('Network response was not ok');

            const blobData = await response.blob();
            const mimeType = getMimeType(fileName);
            const blob = new Blob([blobData], { type: mimeType });

            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Failed to download the file:', error);
        }
    }, []);

    return (
        <div className="flex-1">
            <div className="flex flex-col items-center justify-center py-8">
                <img className="w-24 h-auto" src={logo} alt="Kodak Logo" />
            </div>
            <div className="bg-[#222222] p-5 grid grid-cols-3 gap-4">
                <button
                    className="flex flex-col justify-center items-center text-center gap-1.5"
                    onClick={() => handleDownload(originalUrl)}
                >
                    <FileArchive size={25} strokeWidth={1.6} color="#fff" />
                    <span className="text-white font-semibold text-[0.8rem]">{t('download_photos_taken')}</span>
                </button>
                <button
                    className="flex flex-col justify-center items-center text-center gap-1.5"
                    onClick={() => handleDownload(imageUrl)}
                >
                    <Image size={25} strokeWidth={1.6} color="#fff" />
                    <span className="text-white font-semibold text-[0.8rem]">{t('download_photo')}</span>
                </button>
                <button
                    className="flex flex-col justify-center items-center text-center gap-1.5"
                    onClick={() => handleDownload(videoUrl)}
                >
                    <Video size={25} strokeWidth={1.6} color="#fff" />
                    <span className="text-white font-semibold text-[0.8rem]">{t('download_video')}</span>
                </button>
            </div>
            <div className="flex flex-row justify-center items-center text-center gap-1 py-4 pb-1">
                <CircleHelp size={12} />
                <p className="font-semibold text-xs">{t('check_within_24_hours')}</p>
            </div>
            <div className="flex flex-row justify-center items-center text-center gap-1 py-4 pt-2">
                <Camera size={12} />
                <p className="font-semibold text-xs">{formatDate(createAt)}</p>
            </div>
            <div className="px-10 pb-8 flex flex-col items-center justify-center gap-3">
                {imageUrl && (
                    <img
                        src={`https://kr.object.ncloudstorage.com/new-kodak/${imageUrl}`}
                        alt="Kodak"
                    />
                )}
                {videoUrl && (
                    <video autoPlay loop muted playsInline>
                        <source
                            src={`https://kr.object.ncloudstorage.com/new-kodak/${videoUrl}`}
                            type="video/mp4"
                        />
                    </video>
                )}
            </div>
        </div>
    );
});

export default ViewPhoto;
