import React, {useEffect, useState, useCallback} from 'react';
import {useParams} from "react-router-dom";
import BaseLayout from "../components/layout/BaseLayout";
import axios from "axios";
import SelectLanguage from "./SelectLanguage";
import Loading from "../components/Loading";
import ViewPhoto from "../components/viewPhoto/ViewPhoto";

const ViewPhotoPage = () => {
    const {transactionId} = useParams();
    const [photoData, setPhotoData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [languageSelected, setLanguageSelected] = useState(false);

    // 데이터 불러오기 함수: useCallback으로 메모이제이션
    const fetchPhotoData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_REMOTE_HOST}/api/v1/qr/${transactionId}`, {withCredentials: true});
            if (response.data) {
                setPhotoData(response.data);
            }
        } catch (err) {
            console.error("데이터를 불러오는 데 실패했습니다:", err);
        } finally {
            setLoading(false);
        }
    }, [transactionId]);

    // useEffect 내에서 데이터 불러오기: transactionId가 있을 때만 호출
    useEffect(() => {
        if (transactionId) {
            fetchPhotoData();
        }
    }, [transactionId, fetchPhotoData]);

    // 언어 선택 핸들러: useCallback으로 메모이제이션
    const handleLanguageSelect = useCallback(() => {
        setLanguageSelected(true);
    }, []);

    return (
        <BaseLayout>
            {loading ? (
                <Loading size="w-12 h-12" color="text-blue-500" />
            ) : (
                languageSelected ? (
                    <ViewPhoto
                        originalUrl={photoData?.data?.transactionOriginalImageName}
                        imageUrl={photoData?.data?.transactionImageName}
                        videoUrl={photoData?.data?.transactionVideoName}
                        createAt={photoData?.data?.transactionCreatedAt}
                    />
                ) : (
                    <SelectLanguage
                        photoData={photoData?.data}
                        onLanguageSelect={handleLanguageSelect}
                    />
                )
            )}
        </BaseLayout>
    );
};

export default React.memo(ViewPhotoPage); // 컴포넌트 메모이제이션
