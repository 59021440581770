/**
 * @author : 다니엘 스튜디오
 * @mailto : hello@kimdaniel.studio
 * @created : 2024-04-16
 */

import React from 'react';
import {CircleAlert} from "lucide-react";
import BaseLayout from "../components/layout/BaseLayout";

const PageNotFoundPage = () => {
    return (
        <BaseLayout>
            <div className="flex flex-row justify-center items-center gap-2 flex-1">
                <CircleAlert size={16}/>
                <span className="font-semibold text-sm">Link does not exist.</span>
            </div>
        </BaseLayout>
    );
};

export default PageNotFoundPage;