/**
 * @author : 다니엘 스튜디오
 * @mailto : hello@kimdaniel.studio
 * @created : 2024-04-16
 */

import React from 'react';

const Loading = ({size = 'w-8 h-8', color = 'text-gray-500', className = ''}) => {
    return (
        <div className="flex flex-col justify-center items-center gap-1 px-4 flex-1">
            <svg
                className={`animate-spin ${size} ${color} ${className}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                aria-label="Loading"
                role="status"
            >
                <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                ></circle>
                <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                ></path>
            </svg>
            <div className="text-xs font-semibold">LOADING...</div>
        </div>
    );
};

export default Loading;