/**
 * @author : 다니엘 스튜디오
 * @mailto : hello@kimdaniel.studio
 * @created : 2024-04-16
 */

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './locales/enTranslation.json';
import koTranslation from './locales/koTranslation.json';
import jaTranslation from './locales/jaTranslation.json';
import zhTranslation from './locales/zhTranslation.json';

const resources = {
    en: { translation: enTranslation },
    ko: { translation: koTranslation },
    ja: { translation: jaTranslation }, // 일본어 추가
    zh: { translation: zhTranslation }, // 중국어 추가
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ['navigator', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
            caches: ['cookie']
        }
    });

export default i18n;
